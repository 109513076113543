<template>
  <div class="w-100 fill-height">
    <!-- Alerts -->
    <v-alert
      v-if="error"
      v-text="error"
      type="error"
      dense
      dismissible
      class="ma-3"
    />

    <!-- Page Heading -->
    <pibot-page-heading>
      {{ title }}
    </pibot-page-heading>

    <v-container>
      <!-- There are still assessments left -->
      <template v-if="incident && incident.assessments.length">
        <v-alert
          v-text="errorMessage"
          type="error"
          dense
          class="ma-3"
        />
      </template>

      <!-- Confirm delete -->
      <template v-else-if="incident">
        <v-row>
          <v-spacer />

          <v-col cols="12" md="6">
            <v-card
              elevation="0"
              outlined
            >
              <v-card-title>
                Confirm incident deletion
              </v-card-title>

              <v-divider />

              <v-card-text>
                You are about to delete incident <strong>"{{ incident.incident_name }}"</strong> and all relevant data.
                <br />
                <strong class="error--text text--darken-1">This cannot be undone.</strong> Are you sure you wish to proceed?
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-spacer />

                <v-btn
                  elevation="0"
                  class="mr-3"
                  color="primary darken-1"
                  outlined
                  @click="$router.replace('/eprs/')"
                >
                  Cancel
                </v-btn>

                <pibot-pw-confirm
                  elevation="0"
                  color="error darken-1"
                  text
                  @confirmed="deleteItem"
                >
                  <template #default>Delete</template>
                  <template #title>Authenticate</template>
                  <template #text><span class="error--text text--darken-1">Are you sure you want to permanently delete this incident?</span></template>
                </pibot-pw-confirm>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-spacer />
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'

export default {
  name: 'pibot-eprs-incident-delete',
  mixins: [eprs, mapboxBasicsMixin],
  components: {
    'pibot-page-heading': () => import('@/components/typography/headings/PageHeading'),
    'pibot-pw-confirm': () => import('@/components/buttons/PasswordConfirm')
  },
  data: () => ({
    title: 'Delete incident',
    errorMessage: 'There are still assessments bound to this incident. Please delete the assessments first prior to deleting this incident.'
  }),
  async beforeMount () {
    this.incident = await this.getIncidentById(this.iid)
  },
  beforeDestroy () {
    console.warn('for demo purposes, map cache is disabled')
    this.$store.state.mapbox.map = null
    this.$store.state.mapbox.layerObjs = []
    this.$store.state.mapbox.sourceObjs = []
  //   if (this.map) {
  //     this.setIncidentsToggle(false)
  //     this.setLayerVisibility('eprs-incidents', false)
  //   }
  },
  methods: {
    ...mapMutations({
      setIncidentsToggle: 'eprs/SET_MAP_TOGGLE'
    }),
    ...mapActions({
      deleteIncident: 'eprs/socket_deleteIncident'
    }),
    async deleteItem () {
      await this.deleteIncident(this.iid)
      this.$router.replace('/eprs/')
    }
  }
}
</script>
